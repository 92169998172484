import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader.js'
// import { GroundProjectedSkybox } from 'three/examples/jsm/objects/GroundProjectedSkybox.js'

// Merge
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
//

/**
 * Loaders
 */
const gltfLoader = new GLTFLoader()
const cubeTextureLoader = new THREE.CubeTextureLoader()
const rgbeLoader = new RGBELoader()
const exrLoader = new EXRLoader()
const textureLoader = new THREE.TextureLoader()


/**
 * Base
 */
// Debug
// const gui = new dat.GUI()
// const global = {}


// Merge text
    const fontLoader = new FontLoader()

    fontLoader.load(
        '/fonts/helvetiker_regular.typeface.json',
        (font) =>
        {
            // Material
            const textMaterial = new THREE.MeshStandardMaterial(
                {
                    roughness: 0, 
                    metalness: 1, 
                    color: 0xaaaaaa
                }
      );


        // Text
        const textGeometry = new TextGeometry(
            'Artist\nDigital Nomad\nTechnologist\nNeurodivergent',
            {
                font: font,
                size: 1,
                height: 2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.3,
                bevelSize: 0.04,
                bevelOffset: 0.01,
                bevelSegments: 5,
            }
        )
        textGeometry.center()
        

        const text = new THREE.Mesh(textGeometry, textMaterial);
        
        scene.add(text)
      }
    )

const textMaterial = new THREE.MeshStandardMaterial


// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Update all maeterials
 */
const updateAllMaterials = () =>
{
    scene.traverse((child) =>
    {
        if(child.isMesh && child.material.isMeshStandardMaterial)
        {
            child.material.envMapIntensity = global.envMapIntensity
        }
    })
}

// // AxesHelper
// const axesHelper = new THREE.AxesHelper( 10 );
// scene.add( axesHelper );

/**
 * Environment map
 */
scene.backgroundBlurriness = 0
scene.backgroundIntensity = 1

// gui.add(scene, 'backgroundBlurriness').min(0).max(1).step(0.001)
// gui.add(scene, 'backgroundIntensity').min(0).max(1).step(0.001)


// Global intensity
// global.envMapIntensity = 1
// gui
//     .add(global, 'envMapIntensity')
//     .min(0)
//     .max(10)
//     .step(0.001)
//     .onChange(updateAllMaterials)


// HDR (RGBE) equierectangular
rgbeLoader.load('/environmentMaps/scene7.hdr', (environmentMap) =>
{
    environmentMap.mapping = THREE.EquirectangularReflectionMapping
    environmentMap.colorSpace = THREE.SRGBColorSpace


    scene.background = environmentMap
    // scene.environment = environmentMap
})


/**
 * Torus Knot
 */
const torusKnot = new THREE.Mesh(
    new THREE.TorusKnotGeometry(1, 0.4, 100, 16),
    new THREE.MeshStandardMaterial({ roughness: 0, metalness: 1, color: 0xaaaaaa })
)
torusKnot.position.x = 0.5
torusKnot.position.z = -0.5

torusKnot.position.y = 4
// scene.add(torusKnot)


// Holy donut
const holyDonut = new THREE.Mesh(
    new THREE.TorusGeometry(13, 0.5),
    new THREE.MeshBasicMaterial({ color: new THREE.Color(10, 4, 2) })
)
holyDonut.layers.enable(1)
holyDonut.position.y = 3.5
scene.add(holyDonut)

// Holy donut 02
const holyDonut02 = new THREE.Mesh(
    new THREE.TorusGeometry(20, 0.5),
    new THREE.MeshBasicMaterial({ color: new THREE.Color(10, 4, 2) })
)
holyDonut02.layers.enable(1)
holyDonut02.position.y = -5
scene.add(holyDonut02)

// Cube render target
const cubeRenderTarget = new THREE.WebGLCubeRenderTarget(
    256,
    {
        type: THREE.HalfFloatType
    }
)

scene.environment = cubeRenderTarget.texture

// Cube camera
const cubeCamera = new THREE.CubeCamera(0.1, 100, cubeRenderTarget)
cubeCamera.layers.set(1)

/**
 * Models 
 */
gltfLoader.load(
    '/models/face_scan/BaseChar_Steppe_1.gltf',
    (gltf) =>
    {
        gltf.scene.traverse((child) => {
            if (child.isMesh) {
                child.material = new THREE.MeshStandardMaterial({ 
                    roughness: 0, 
                    metalness: 1, 
                    color: 0xaaaaaa,
                    // wireframe: true,
                    // transparent: true // needs opacity   
                });
            }
        });

        gltf.scene.scale.set(4.5, 4.5, 4.5)

        gltf.scene.position.y = 2
        gltf.scene.position.x = 1

        gltf.scene.rotation.y = 0



        scene.add(gltf.scene)

        updateAllMaterials()
    }
)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.set(3, 2, 18)
scene.add(camera)



// Controls
const controls = new OrbitControls(camera, canvas)
controls.target.y = 1
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()
const tick = () =>
{
    // Time
    const elapsedTime = clock.getElapsedTime()

    // Real time environment map
    if(holyDonut)
    {
        holyDonut.rotation.x = Math.sin(elapsedTime) * 2
        // holyDonut.rotation.y = Math.sin(elapsedTime) * 2

        cubeCamera.update(renderer, scene)

    }

    if(holyDonut02)
    {
        holyDonut02.rotation.y = Math.sin(elapsedTime) * 2
        holyDonut02.rotation.z = Math.sin(elapsedTime) * 2

        // holyDonut.rotation.y = Math.sin(elapsedTime) * 2

        cubeCamera.update(renderer, scene)

    }

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)
    // renderer.setClearColor(0xFFFFFF, 1);

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()

// ... your existing Three.js code ...

// JavaScript for info button
const infoBtn = document.getElementById('infoBtn');
const infoPanel = document.getElementById('infoPanel');

infoBtn.addEventListener('click', function() {
    if(infoPanel.classList.contains('open')) {
        infoPanel.classList.remove('open');
    } else {
        infoPanel.classList.add('open');
    }
});
